<template>
  <div class="system-set-contaner">
    <el-row class="device-list">
      <el-row class="header" type="flex" align="middle">
        <p class="title c3 f22">安全级别:</p>
        <img src="@/assets/images/home/dunpai.png" alt="" />
        <span class="height">高</span>
        <span class="text">建议您启动全部安全设置，以保障账户及资金安全</span>
      </el-row>
      <el-row class="item" type="flex" justify="space-between" align="middle">
        <div class="box">
          <img src="@/assets/images/home/dunpai.png" alt="" />
          <span class="pwd">登录密码</span>
          <div class="line"></div>
          <span class="text"
            >互联网账号存在被盗风险，建议您定期更改密码以保护账户安全。</span
          >
        </div>
        <div class="btn">
          <button>
            <router-link :to="{ name: 'verify-pwd' }">修改</router-link>
          </button>
        </div>
      </el-row>
      <el-row class="item" type="flex" justify="space-between" align="middle">
        <div class="box">
          <img src="@/assets/images/home/dunpai.png" alt="" />
          <span class="pwd">手机验证</span>
          <div class="line"></div>
          <span class="text">您验证的手机：{{ mobile }}</span>
        </div>
        <div class="btn">
          <button>
            <router-link :to="{ name: 'verify-phone' }">修改</router-link>
          </button>
        </div>
      </el-row>
    </el-row>
    <button class="loginOut" @click="outLogin">退出登录</button>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  computed: {
    mobile() {
      let res1 = this.$store.state.userInfo.mobile.slice(0, 3);
      let res2 = this.$store.state.userInfo.mobile.slice(-3);
      return res1 + "****" + res2;
    },
  },

  mounted() {},

  methods: {
    //退出登录
    outLogin() {
      this.$API
        .outLogin()
        .then((res) => {
          if (res.code == 1) {
            //这是退出登录你看看有问题没，退出登录，去首页吗？没问题
            this.$store.commit("clearInfo");
            this.$router.push("/home");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.system-set-contaner {
  width: 100%;
  height: 100%;
  .loginOut {
    width: 225px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #bfbfbf;
    font-size: 20px;
    color: #ffffff;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 300px;
  }
  .header {
    padding-bottom: 25px;
    border-bottom: 1px solid #eeeeee;
    width: 912px;
    .title {
      border: none;
    }
  }
  box-sizing: border-box;
  padding: 32px;
  .device-list {
    width: 912px;
    margin-top: 0px;
    .line {
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 24px;
    }
    img {
      width: 24px;
      height: 24px;
      margin-left: 10px;
    }
    .height {
      font-size: 22px;
      color: #4aa800;
      margin-left: 5px;
    }
    .text {
      font-size: 15px;
      margin-left: 32px;
      color: #999999;
    }
  }
  .item {
    width: 100%;
    margin-top: 52px;
    display: flex;
    align-items: center;
    .box {
      img {
        width: 18px;
        height: 18px;
      }
      .pwd {
        font-size: 16px;
        color: #333333;
        margin-left: 5px;
        position: relative;
        top: -3px;
      }
      .line {
        width: 1px;
        height: 16px;
        background: #999999;
        margin-left: 24px;
        margin-right: 24px;
        display: inline-block;
        position: relative;
        top: 3px;
      }
      .text {
        font-size: 15px;
        color: #999999;
        position: relative;
        top: -3px;
      }
    }
    .btn {
      button {
        width: 76px;
        height: 40px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #333333;
        font-size: 14px;
        color: #333333;
      }
    }
  }
}
</style>